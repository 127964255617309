import {afterNextRender,ChangeDetectorRef,Component,DestroyRef,inject,Inject,Optional,PLATFORM_ID,REQUEST,signal,WritableSignal} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {GalleryItem,ImageItem,VideoItem} from 'ng-gallery';
import {DOCUMENT,isPlatformServer} from '@angular/common';
import {ActivatedRoute,Router,UrlSegment} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {tap} from 'rxjs';
import {ComponentType} from '@angular/cdk/portal';
import {LoginPopupComponent} from '../authentication/login-popup/login-popup.component';
import {SignupPopupComponent} from '../authentication/signup-popup/signup-popup.component';
import {ForgotPasswordPopupComponent} from '../authentication/forgot-password-popup/forgot-password-popup.component';
import {environment} from '../../environments/environment';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {Meta,Title} from '@angular/platform-browser';

@Component({
	selector:'app-index',
	imports:[
		SharedModule
	],
	templateUrl:'./index.component.html',
	styleUrl:'./index.component.scss'
})
export class IndexComponent{
	protected readonly environment=environment;
	private activatedRoute:ActivatedRoute=inject(ActivatedRoute);
	private matDialog:MatDialog=inject(MatDialog);
	private router:Router=inject(Router);
	private readonly changeDetectorRef:ChangeDetectorRef=inject(ChangeDetectorRef);
	private readonly destroyRef:DestroyRef=inject(DestroyRef);
	pictures:GalleryItem[]&VideoItem[];
	
	constructor(
		title:Title,
		meta:Meta,
		@Inject(DOCUMENT) document:Document,
		@Inject(PLATFORM_ID) platformId:object,
		@Inject(REQUEST) request?:Request
	){
		title.setTitle('Bendita');
		meta.updateTag({content:'Bendita'},'property="og:title"');
		meta.updateTag(
			{
				content:(isPlatformServer(platformId) && request) ? request.url : document.location.href
			},
			'property="og:url"'
		);
		meta.updateTag({content:'Plantas & Semillas Abierto las 24 horas'},'name="description"');
		meta.updateTag({content:'Plantas & Semillas Abierto las 24 horas'},'property="og:description"');
		meta.updateTag({content:'bendita, grow, shop, semillas, plantas'},'name="keywords"');
		meta.updateTag({content:'https://www.benditagrow.com/icons/icon-512x512.png'},'property="og:image"');
		this.pictures=[
			new ImageItem({
				src:'/images/home1.png',
				thumb:'/images/home1.png',
				alt:'Bendita'
			}),
			new ImageItem({
				src:'/images/home2.png',
				thumb:'/images/home2.png',
				alt:'Bendita'
			}),
			new ImageItem({
				src:'/images/home3.png',
				thumb:'/images/home3.png',
				alt:'Bendita'
			}),
			new ImageItem({
				src:'/images/home4.png',
				thumb:'/images/home4.png',
				alt:'Bendita'
			}),
			new VideoItem({
				src:'/videos/home1.mp4',
				type:'video/mp4',
				poster:'/videos/home1.png',
				thumb:'/videos/home1.png',
				controls:false,
				mute:true,
				autoplay:true,
				loop:true
			})
		];
		
		afterNextRender(():void=>{
			document.defaultView?.scrollTo(0,0);
			
			if(!isPlatformServer(platformId)){
				this.activatedRoute.url.pipe(
					tap((urlSegments:UrlSegment[]):void=>{
						let component:ComponentType<any>|undefined;
						switch(urlSegments[0]?.path){
							case('login'):{
								component=LoginPopupComponent;
								break;
							}
							case('signup'):{
								component=SignupPopupComponent;
								break;
							}
							case('forgot-password'):{
								component=ForgotPasswordPopupComponent;
								break;
							}
						}
						if(component){
							this.matDialog.open(component)
							.afterClosed()
							.subscribe((data:any):void=>{
								if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
								this.changeDetectorRef.detectChanges();
							});
						}
					}),
					takeUntilDestroyed(this.destroyRef)
				).subscribe();
			}
		});
	}
	
	
}
