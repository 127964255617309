<mat-toolbar role="heading">
	@defer (on immediate) {
		<mat-toolbar-row class="header-content">
			<button mat-icon-button class="menu-button" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'" (click)="drawer().toggle()" aria-label="Toggle sidenav">
				<mat-icon>menu</mat-icon>
			</button>
			<a [routerLink]="['/']" rel="bookmark" class="logo-home">
				<img ngSrc="/icons/favicon.png" alt="home" [height]="(isXXSmallWindow | async)?.matches ? 32 : 64" [width]="(isXXSmallWindow | async)?.matches ? 32 : 64" priority>
			</a>
			<div class="spacer"></div>
			<button mat-icon-button aria-label="Search" class="header-button" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'" (click)="toggleSearch()">
				<mat-icon>search</mat-icon>
			</button>
			@if (authenticationService.user(); as user) {
				<a class="header-button-container" aria-label="profile" [matMenuTriggerFor]="menuLogin">
					<button mat-icon-button class="header-button" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'">
						<mat-icon aria-label="Profile">person</mat-icon>
					</button>
					<span *ngIf="!(isXSmallWindow | async)?.matches" class="app-12">{{user.firstName}}</span>
					<mat-menu #menuLogin="matMenu">
						<mat-nav-list>
							<a mat-list-item [routerLink]="['/profile','account']" aria-label="profile" role="listitem">
								<p>Mi perfil</p>
							</a>
							<a mat-list-item [routerLink]="['/profile','addresses']" aria-label="addresses" role="listitem">
								<p>Mis direcciones</p>
							</a>
							<a mat-list-item [routerLink]="['/profile','orders']" aria-label="orders" role="listitem">
								<p>Mis ordenes</p>
							</a>
							<a mat-list-item (click)="authenticationService.logout()" (keydown)="authenticationService.logout()" aria-label="login" role="listitem">
								<span matListItemTitle>Log out</span>
								<mat-icon matListItemIcon>logout</mat-icon>
							</a>
						</mat-nav-list>
					</mat-menu>
				</a>
			} @else {
				<a class="header-button-container" aria-label="login" [matMenuTriggerFor]="menuLogin">
					<button mat-icon-button class="header-button" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'">
						<mat-icon aria-label="Login">person</mat-icon>
					</button>
					<span *ngIf="!(isXSmallWindow | async)?.matches" class="app-12">Iniciar sesion / Registro</span>
					<mat-menu #menuLogin="matMenu">
						<mat-nav-list>
							<a mat-list-item (click)="openLogin()" (keydown)="openLogin()" aria-label="login" role="listitem">
								<p>Log in</p>
							</a>
							<a mat-list-item (click)="openSignup()" (keydown)="openSignup()" aria-label="login" role="listitem">
								<p>Crear cuenta</p>
							</a>
							<a mat-list-item (click)="openForgotPassword()" (keydown)="openForgotPassword()" aria-label="login" role="listitem">
								<p>Recuperar contraseña</p>
							</a>
						</mat-nav-list>
					</mat-menu>
				</a>
			}
			<a *ngIf="appService.cart() as cart" class="header-button-container no-decoration" aria-label="cart" [routerLink]="['/cart']">
				<button mat-icon-button class="header-button" [ngClass]="(isXXSmallWindow | async)?.matches ? 'app-16' : 'app-24'">
					<mat-icon aria-label="Cart" [matBadge]="appService.cartItemsCount()" matBadgeSize="large" matBadgePosition="above after" aria-hidden="false">shopping_cart</mat-icon>
				</button>
				<span *ngIf="!(isXSmallWindow | async)?.matches" class="app-12">Carrito</span>
			</a>
		</mat-toolbar-row>
	} @placeholder {
		<mat-toolbar-row>
			<a [routerLink]="['/']" rel="bookmark" class="visually-hidden">
				<img ngSrc="/icons/favicon.png" alt="home" [height]="50" [width]="50" priority>
			</a>
		</mat-toolbar-row>
	}
</mat-toolbar>
<div #searchContainer class="search-autocomplete" (focusout)="focusoutSearch()" [hidden]="true">
	<mat-form-field class="search-autocomplete-form-field">
		<input #searchInputHeader #searchAutocompleteHeaderTrigger="matAutocompleteTrigger" type="search" placeholder="Busqueda" aria-label="search" matInput [formControl]="search" [matAutocomplete]="searchAutocompleteHeader" (search)="navigateSearch(searchInputHeader.value);" appInputEmptyToNull>
		<mat-icon matSuffix (click)="navigateSearch(searchInputHeader.value);" class="app-pointer">search</mat-icon>
	</mat-form-field>
	<mat-autocomplete #searchAutocompleteHeader="matAutocomplete" [hideSingleSelectionIndicator]="true">
		@for (product of filteredProducts | async; track product.id; let i=$index) {
			<mat-divider *ngIf="i!==0"></mat-divider>
			<mat-option [value]="product.name" class="search-option" [routerLink]="['/products',product.slug]">
				<img class="search-option-item" [ngSrc]="product.pictures[0].small" [width]="20" [height]="20" [alt]="product.name">
				<div class="search-option-text">
					<p class="search-option-item-name">{{product.name}}</p>
					<i class="search-option-item search-option-highlights app-12">{{product.searchHighlights}}</i>
				</div>
			</mat-option>
		}
	</mat-autocomplete>
</div>
@defer (on immediate) {
	<mat-toolbar *ngIf="!(isXSmallWindow | async)?.matches">
		<mat-toolbar-row>
			<mat-nav-list class="menu-toolbar">
				<a [matMenuTriggerFor]="menuProducts" mat-list-item [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:false}" role="button">
					<span>Productos</span>
					<mat-menu #menuProducts="matMenu">
						<mat-nav-list>
							<a mat-list-item [routerLink]="['/products']">
								<span class="app-14">Todos los productos</span>
							</a>
							@for (category of appService.productCategories(); track category.id) {
								<a mat-list-item [routerLink]="['/products']" [queryParams]="{category:category.slug}" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}">
									<span>{{category.label}}</span>
								</a>
							}
						</mat-nav-list>
					</mat-menu>
				</a>
				<mat-divider vertical class="menu-toolbar-divider"></mat-divider>
				<a mat-list-item [routerLink]="['/contact']" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}">
					<span>Contacto</span>
				</a>
				<mat-divider vertical class="menu-toolbar-divider"></mat-divider>
				<a mat-list-item [routerLink]="['/envios']" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}">
					<span>Politica de envios</span>
				</a>
				<mat-divider vertical class="menu-toolbar-divider"></mat-divider>
				<a mat-list-item [routerLink]="['/blog']" [routerLinkActive]="'tab-active app-elevation1'" [routerLinkActiveOptions]="{exact:true}">
					<span>Blog</span>
				</a>
			</mat-nav-list>
		</mat-toolbar-row>
	</mat-toolbar>
} @placeholder {
	<mat-nav-list class="visually-hidden">
		<span>Productos</span>
		<mat-nav-list>
			<a mat-list-item [routerLink]="['/products']">
				<span>Todos los productos</span>
			</a>
			@for (category of appService.productCategories(); track category.id) {
				<a mat-list-item [routerLink]="['/products']" [queryParams]="{category:category.slug}">
					<span>{{category.label}}</span>
				</a>
			}
		</mat-nav-list>
		<a mat-list-item [routerLink]="['/contact']">
			<span>Contacto</span>
		</a>
		<a mat-list-item [routerLink]="['/envios']">
			<span>Politica de envios</span>
		</a>
		<a mat-list-item [routerLink]="['/blog']">
			<span>Blog</span>
		</a>
	</mat-nav-list>
}

