import {Component,inject,signal,WritableSignal} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {PopupComponent} from '../../misc/components/popup/popup.component';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {FormControl,FormGroup,Validators} from '@angular/forms';
import {InputEmptyToNullDirective} from '../../misc/directives/input-empty-to-null/input-empty-to-null.directive';
import {FormMessageComponent} from '../../misc/components/form-message/form-message.component';
import {MatButton} from '@angular/material/button';


@Component({
	selector:'app-signup-popup',
	imports:[
		SharedModule,
		InputEmptyToNullDirective,
		FormMessageComponent,
		MatButton
	],
	templateUrl:'./signup-popup.component.html',
	styleUrl:'./signup-popup.component.scss'
})
export class SignupPopupComponent extends PopupComponent{
	private authenticationService:AuthenticationService=inject(AuthenticationService);
	email:FormControl=new FormControl(undefined,[
		Validators.required,
		Validators.email
	]);
	password:FormControl=new FormControl(undefined,[
		Validators.required
	]);
	firstName:FormControl=new FormControl(undefined,[
		Validators.required
	]);
	lastName:FormControl=new FormControl(undefined,[
		Validators.required
	]);
	form:FormGroup=new FormGroup({
		email:this.email,
		password:this.password,
		firstName:this.firstName,
		lastName:this.lastName
	});
	serverError:WritableSignal<any>=signal(null);
	serverResponse:WritableSignal<any>=signal(null);
	submitButtonActive:WritableSignal<boolean>=signal(true);
	
	constructor(){
		super(
			inject(MatDialogRef),
			inject(Router)
		);
		
	}
	
	signUp():void{
		if(this.submitButtonActive()){
			if(!this.form.valid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive.set(false);
				this.serverError.set(null);
				this.serverResponse.set(null);
				const next=():void=>{
					this.submitButtonActive.set(true);
					this.close(false,'/profile');
				};
				const error=(error:any):void=>{
					this.submitButtonActive.set(true);
					this.serverError.set(error);
				};
				this.authenticationService.signUp({
					email:this.form.get('email')?.value,
					password:this.form.get('password')?.value,
					firstName:this.form.get('firstName')?.value,
					lastName:this.form.get('lastName')?.value
				})
				.subscribe({next,error});
			}
		}
	}
	
}
