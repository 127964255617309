import {inject,Injectable} from '@angular/core';
import {map,Observable} from 'rxjs';
import {ApolloQueryResult} from '@apollo/client/core';
import {SearchProductsGQL,SearchProductsQuery,SearchProductsQueryVariables} from './graphql/search-products.generated';

@Injectable({
	providedIn:'root'
})
export class SearchService{
	private readonly searchProductsGQL:SearchProductsGQL=inject(SearchProductsGQL);
	
	searchProducts(variables:SearchProductsQueryVariables):Observable<SearchProductsQuery['searchProducts']>{
		return this.searchProductsGQL
		.fetch(variables,{})
		.pipe(
			map(
				(result:ApolloQueryResult<SearchProductsQuery>):SearchProductsQuery['searchProducts']=>{
					if(!result.data?.searchProducts) return [];
					return result.data.searchProducts;
				}
			)
		);
	}
	
}
