import {Component} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';

@Component({
	selector:'app-cart-item-deleted-snack',
	imports:[
		SharedModule
	],
	templateUrl:'./cart-item-deleted-snack.component.html',
	styleUrl:'./cart-item-deleted-snack.component.scss'
})
export class CartItemDeletedSnackComponent{

}
