import {Component,inject,signal,WritableSignal} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {PopupComponent} from '../../misc/components/popup/popup.component';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {FormControl,FormGroup,Validators} from '@angular/forms';
import {InputEmptyToNullDirective} from '../../misc/directives/input-empty-to-null/input-empty-to-null.directive';
import {FormMessageComponent} from '../../misc/components/form-message/form-message.component';

@Component({
	selector:'app-forgot-password-popup',
	imports:[
		SharedModule,
		InputEmptyToNullDirective,
		FormMessageComponent
	],
	templateUrl:'./forgot-password-popup.component.html',
	styleUrl:'./forgot-password-popup.component.scss'
})
export class ForgotPasswordPopupComponent extends PopupComponent{
	private authenticationService:AuthenticationService=inject(AuthenticationService);
	email:FormControl=new FormControl(undefined,[
		Validators.required,
		Validators.email
	]);
	form:FormGroup=new FormGroup({
		email:this.email
	});
	serverError:WritableSignal<any>=signal(null);
	serverResponse:WritableSignal<any>=signal(null);
	submitButtonActive:WritableSignal<boolean>=signal(true);
	
	constructor(){
		super(
			inject(MatDialogRef),
			inject(Router)
		);
		
	}
	
	passwordResetToken():void{
		if(this.submitButtonActive()){
			if(!this.form.valid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive.set(false);
				this.serverError.set(null);
				this.serverResponse.set(null);
				const next=():void=>{
					this.submitButtonActive.set(true);
					this.serverResponse.set('Se ha enviado un email para reestablecer la contraseña.');
				};
				const error=(error:any):void=>{
					this.submitButtonActive.set(true);
					this.serverError.set(error);
				};
				this.authenticationService.passwordResetToken({
					email:this.form.get('email')?.value
				})
				.subscribe({next,error});
			}
		}
	}
}
