import {CanActivateChildFn,CanActivateFn,CanMatchFn,GuardResult,Router} from '@angular/router';
import {filter,firstValueFrom,map} from 'rxjs';
import {inject} from '@angular/core';
import {UserResponseDto} from '../authentication/dto/user-response.dto';
import {AuthenticationService} from '../authentication/authentication.service';
import {toObservable} from '@angular/core/rxjs-interop';

export const profileGuardActivate:CanActivateFn=async():Promise<GuardResult>=>{
	const router:Router=inject(Router);
	return firstValueFrom(toObservable(inject(AuthenticationService).user).pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):GuardResult=>{
			if(!user){
				return router.parseUrl('/login');
			}else{
				return true;
			}
		})
	));
};

export const profileGuardMatch:CanMatchFn=async():Promise<GuardResult>=>{
	const router:Router=inject(Router);
	return firstValueFrom(toObservable(inject(AuthenticationService).user).pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):GuardResult=>{
			if(!user){
				return router.parseUrl('/login');
			}else{
				return true;
			}
		})
	));
};

export const profileGuardActivateChild:CanActivateChildFn=async():Promise<GuardResult>=>{
	const router:Router=inject(Router);
	return firstValueFrom(toObservable(inject(AuthenticationService).user).pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):GuardResult=>{
			if(!user){
				return router.parseUrl('/login');
			}else{
				return true;
			}
		})
	));
};

