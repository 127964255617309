import {Component} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';

@Component({
	template:'',
	standalone:true
})
export class PopupComponent{
	constructor(
		public matDialogRef:MatDialogRef<any>,
		public router:Router
	){
	}
	
	close(timeout?:boolean,redirectUrl?:string):void{
		if(redirectUrl) this.router.navigate([redirectUrl]);
		if(timeout) setTimeout(():void=>this.matDialogRef.close(),200);
		else this.matDialogRef.close();
	}
	
}
