import {afterNextRender,Component,DestroyRef,inject,input,InputSignal,signal,WritableSignal} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../../app.service';
import {SharedModule} from '../../../shared/shared.module';
import {environment} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {BreakpointObserver,Breakpoints,BreakpointState} from '@angular/cdk/layout';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {DOCUMENT} from '@angular/common';
import {CUSTOM_BREAKPOINT} from '../../../common/enums/custom-breakpoint.enum';

@Component({
	selector:'app-drawer',
	imports:[
		SharedModule
	],
	templateUrl:'./drawer.component.html',
	styleUrl:'./drawer.component.scss'
})
export class DrawerComponent{
	drawer:InputSignal<MatDrawer>=input.required();
	document:Document=inject(DOCUMENT);
	destroyRef:DestroyRef=inject(DestroyRef);
	breakpointObserver:BreakpointObserver=inject(BreakpointObserver);
	appService:AppService=inject(AppService);
	authenticationService:AuthenticationService=inject(AuthenticationService);
	protected readonly environment=environment;
	isXSmallWindow:Observable<BreakpointState>=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	isXXSmallWindow:Observable<BreakpointState>=this.breakpointObserver.observe([CUSTOM_BREAKPOINT.XXSmall])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	matchesDark:WritableSignal<boolean|undefined>=signal(undefined);
	
	constructor(){
		afterNextRender(async():Promise<void>=>{
			if(this.document.defaultView){
				const darkColorScheme:MediaQueryList=this.document.defaultView.matchMedia('(prefers-color-scheme: dark)');
				darkColorScheme.addEventListener('change',():void=>{
					this.matchesDark.set(darkColorScheme.matches);
				});
				setTimeout(():void=>{
					darkColorScheme.dispatchEvent(new MediaQueryListEvent('change'));
				},200);
			}
		});
	}
	
}
