import {Component,inject,signal,WritableSignal} from '@angular/core';
import {SharedModule} from '../../shared/shared.module';
import {PopupComponent} from '../../misc/components/popup/popup.component';
import {MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {AuthenticationService} from '../authentication.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {FormControl,FormGroup,Validators} from '@angular/forms';
import {SnackMessageComponent} from '../../misc/components/snack-message/snack-message.component';
import {InputEmptyToNullDirective} from '../../misc/directives/input-empty-to-null/input-empty-to-null.directive';
import {FormMessageComponent} from '../../misc/components/form-message/form-message.component';
import {UserResponseDto} from '../dto/user-response.dto';

@Component({
	selector:'app-login-popup',
	imports:[
		SharedModule,
		InputEmptyToNullDirective,
		FormMessageComponent
	],
	templateUrl:'./login-popup.component.html',
	styleUrl:'./login-popup.component.scss'
})
export class LoginPopupComponent extends PopupComponent{
	private authenticationService:AuthenticationService=inject(AuthenticationService);
	private matSnackBar:MatSnackBar=inject(MatSnackBar);
	email:FormControl=new FormControl(undefined,[
		Validators.required,
		Validators.email
	]);
	password:FormControl=new FormControl(undefined,[
		Validators.required
	]);
	form:FormGroup=new FormGroup({
		email:this.email,
		password:this.password
	});
	serverError:WritableSignal<any>=signal(null);
	serverResponse:WritableSignal<any>=signal(null);
	submitButtonActive:WritableSignal<boolean>=signal(true);
	
	constructor(){
		super(
			inject(MatDialogRef),
			inject(Router)
		);
		
	}
	
	login():void{
		if(this.submitButtonActive()){
			if(!this.form.valid){
				this.form.markAllAsTouched();
			}else{
				this.submitButtonActive.set(false);
				this.serverError.set(null);
				this.serverResponse.set(null);
				const next=async(user:UserResponseDto|null|undefined):Promise<void>=>{
					this.submitButtonActive.set(true);
					if(this.router.url==='/login') this.close(false,'/profile');
					else{
						this.matSnackBar.openFromComponent(SnackMessageComponent,{
							data:{
								serverResponseInput:'Bienvenido/a '+user?.firstName
							}
						});
						this.close();
					}
				};
				const error=(error:any):void=>{
					this.submitButtonActive.set(true);
					this.serverError.set(error);
				};
				this.authenticationService.login({
					email:this.form.get('email')?.value,
					password:this.form.get('password')?.value
				})
				.subscribe({next,error});
			}
		}
	}
	
	
}
