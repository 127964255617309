import {Routes} from '@angular/router';
import {IndexComponent} from './index/index.component';
import {authenticationGuardActivate} from './authentication/authentication.guard';
import {profileGuardMatch} from './profile/profile.guard';
import {ProductsComponent} from './products/products.component';
import {CheckOutComponent} from './check-out/check-out.component';

export const routes:Routes=[
	{
		path:'error',
		loadComponent:()=>import('./error/error.component').then(m=>m.ErrorComponent),
		pathMatch:'full',
		data:{preload:true}
	},
	{
		path:'',
		component:IndexComponent,
		pathMatch:'full'
	},
	{
		path:'login',
		component:IndexComponent,
		pathMatch:'full',
		canActivate:[authenticationGuardActivate]
	},
	{
		path:'signup',
		component:IndexComponent,
		pathMatch:'full',
		canActivate:[authenticationGuardActivate]
	},
	{
		path:'forgot-password',
		component:IndexComponent,
		pathMatch:'full',
		canActivate:[authenticationGuardActivate]
	},
	{
		path:'reset-password',
		loadChildren:()=>import('./reset-password/reset-password.routes').then(m=>m.routes),
		data:{preload:true}
	},
	{
		path:'email-verified',
		loadComponent:()=>import('./email-verified/email-verified.component').then(m=>m.EmailVerifiedComponent),
		pathMatch:'full',
		data:{preload:true}
	},
	{
		path:'profile',
		loadChildren:()=>import('./profile/profile.routes').then(m=>m.routes),
		canMatch:[profileGuardMatch]
	},
	{
		path:'contact',
		loadComponent:()=>import('./contact/contact.component').then(m=>m.ContactComponent),
		pathMatch:'full',
		data:{preload:true}
	},
	{
		path:'products',
		loadChildren:()=>import('./products/products.routes').then(m=>m.routes),
		data:{preload:true}
	},
	{
		path:'envios',
		loadComponent:()=>import('./envios/envios.component').then(m=>m.EnviosComponent),
		pathMatch:'full',
		data:{preload:true}
	},
	{
		path:'cart',
		loadComponent:()=>import('./cart/cart.component').then(m=>m.CartComponent),
		pathMatch:'full',
		data:{preload:true}
	},
	{
		path:'check-out',
		component:CheckOutComponent,
		data:{preload:true}
	},
	{
		path:'tracking',
		loadComponent:()=>import('./tracking/tracking.component').then(m=>m.TrackingComponent),
		data:{preload:true}
	},
	{
		path:'blog',
		loadChildren:()=>import('./blog/blog.routes').then(m=>m.routes),
		data:{preload:true}
	},
	{
		path:'search',
		loadChildren:()=>import('./search/search.routes').then(m=>m.routes),
		data:{preload:true}
	},
	{
		path:'**',
		loadComponent:()=>import('./not-found/not-found.component').then(m=>m.NotFoundComponent),
		data:{preload:true}
	}
];
