import {afterNextRender,computed,effect,inject,Inject,Injectable,linkedSignal,Optional,ResourceRef,Signal,signal,WritableSignal} from '@angular/core';
import {catchError,map,Observable,tap} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApolloQueryResult} from '@apollo/client/core';
import {LocalStorageService} from 'ngx-webstorage';
import {DOCUMENT} from '@angular/common';
import {GetInitialDataQuery,GetInitialDataGQL,GetInitialDataQueryVariables} from './common/graphql/get-initial-data.generated';
import {PayPalNamespace} from '@paypal/paypal-js';
import {GOOGLE_MAPS,GOOGLE_PLACES,MERCADO_PAGO,PAYPAL} from './shared/constants';
import {Loader} from '@googlemaps/js-api-loader';
import {environment} from '../environments/environment';
import {GetCartGQL,GetCartQuery,GetCartQueryVariables} from './common/graphql/get-cart.generated';
import {CreateCartItemGQL,CreateCartItemMutation,CreateCartItemMutationVariables} from './common/graphql/create-cart-item.generated';
import {MutationResult} from 'apollo-angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {CartItemCreatedSnackComponent} from './misc/components/cart-item-created-snack/cart-item-created-snack.component';
import {rxResource} from '@angular/core/rxjs-interop';
import {SnackMessageComponent} from './misc/components/snack-message/snack-message.component';
import {DeleteCartItemGQL,DeleteCartItemMutation,DeleteCartItemMutationVariables} from './common/graphql/delete-cart-item.generated';
import {CartItemDeletedSnackComponent} from './misc/components/cart-item-deleted-snack/cart-item-deleted-snack.component';

let hasCookieAccess=false;

async function checkHasCookieAccess(){
// 	// Check if Storage Access API is supported
// 	if (!document.requestStorageAccess) {
// 		// Storage Access API is not supported so best we can do is
// 		// hope it's an older browser that doesn't block 3P cookies.
// 		return true;
// 	}
//
// 	// Check if access has already been granted
// 	if (await document.hasStorageAccess()) {
// 		return true;
// 	}
//
// 	// Check the storage-access permission
// 	// Wrap this in a try/catch for browsers that support the
// 	// Storage Access API but not this permission check
// 	// (e.g. Safari and older versions of Firefox).
// 	let permission;
// 	try {
// 		permission = await navigator.permissions.query(
// 			// @ts-ignore
// 			{name: 'storage-access'}
// 		);
// 	} catch (error) {
// 		// storage-access permission not supported. Assume no cookie access.
// 		return false;
// 	}
//
// 	if (permission) {
// 		if (permission.state === 'granted') {
// 			// Permission has previously been granted so can just call
// 			// requestStorageAccess() without a user interaction and
// 			// it will resolve automatically.
// 			try {
// 				await document.requestStorageAccess();
// 				return true;
// 			} catch (error) {
// 				// This shouldn't really fail if access is granted, but return false
// 				// if it does.
// 				return false;
// 			}
// 		} else if (permission.state === 'prompt') {
// 			// Need to call requestStorageAccess() after a user interaction
// 			// (potentially with a prompt). Can't do anything further here,
// 			// so handle this in the click handler.
// 			return false;
// 		} else if (permission.state === 'denied') {
// 			// Currently not used. See:
// 			// https://github.com/privacycg/storage-access/issues/149
// 			return false;
// 		}
// 	}
//
	// By default return false, though should really be caught by one of above.
	return false;
}

@Injectable({
	providedIn:'root'
})
export class AppService{
	private document:Document=inject(DOCUMENT);
	private localStorageService:LocalStorageService=inject(LocalStorageService);
	private readonly httpClient:HttpClient=inject(HttpClient);
	private readonly getInitialDataGQL:GetInitialDataGQL=inject(GetInitialDataGQL);
	private readonly getCartGQL:GetCartGQL=inject(GetCartGQL);
	private readonly createCartItemGQL:CreateCartItemGQL=inject(CreateCartItemGQL);
	private readonly deleteCartItemGQL:DeleteCartItemGQL=inject(DeleteCartItemGQL);
	private readonly matSnackBar:MatSnackBar=inject(MatSnackBar);
	private initialData:ResourceRef<GetInitialDataQuery>=rxResource<GetInitialDataQuery,GetInitialDataQueryVariables>({
		request:()=>({cartId:this.localStorageService.retrieve('cart-id')}),
		loader:(params)=>
			this.getInitialDataGQL.fetch(params.request)
			.pipe(
				map((result:ApolloQueryResult<GetInitialDataQuery>):GetInitialDataQuery=>result.data)
			)
	});
	cart:WritableSignal<GetInitialDataQuery['getCart']>=linkedSignal<{initialData:WritableSignal<GetInitialDataQuery|undefined>},GetInitialDataQuery['getCart']>({
		source:()=>({initialData:this.initialData.value}),
		computation:(source)=>source.initialData()?.getCart
	});
	cartItemsCount:Signal<number>=computed(
		()=>this.cart()?.items?.reduce((previousValue:number,currentValue:{quantity:number}):number=>previousValue+currentValue.quantity,0) || 0
	);
	cartSubtotal:Signal<number>=computed(
		()=>this.cart()?.items?.reduce(
			(previousValue:number,currentValue:{quantity:number,product:{price:number}}):number=>{
				return previousValue+(currentValue.quantity*currentValue.product.price);
			},
			0
		) || 0
	);
	cartDiscount:Signal<number>=computed(
		()=>this.cart()?.items?.reduce(
			(previousValue:number,currentValue:{quantity:number,product:{price:number,discountPercent?:number|null}}):number=>{
				if(currentValue.product.discountPercent) return previousValue+(currentValue.quantity*(currentValue.product.price*(currentValue.product.discountPercent/100)));
				else return previousValue;
			},
			0
		) || 0
	);
	cartTotal:Signal<number>=computed(
		()=>this.cart()?.items?.reduce(
			(previousValue:number,currentValue:{quantity:number,product:{price:number,discountPercent?:number|null}}):number=>{
				if(currentValue.product.discountPercent) return previousValue+(currentValue.quantity*(currentValue.product.price-(currentValue.product.price*(currentValue.product.discountPercent/100))));
				else return previousValue+(currentValue.quantity*currentValue.product.price);
			},
			0
		) || 0
	);
	productCategories:Signal<GetInitialDataQuery['getProductCategories']>=computed(
		()=>this.initialData.value()?.getProductCategories ?? []
	);
	stores:Signal<GetInitialDataQuery['getStores']>=computed(
		()=>this.initialData.value()?.getStores ?? []
	);
	orderStatuses:Signal<GetInitialDataQuery['getOrderStatuses']>=computed(
		()=>this.initialData.value()?.getOrderStatuses ?? []
	);
	theme:WritableSignal<string|null>=signal(this.localStorageService.retrieve('theme-id'));
	paypal?:PayPalNamespace;
	googleMaps?:google.maps.MapsLibrary;
	googlePlaces?:google.maps.PlacesLibrary;
	mercadoPago?:any;
	
	constructor(
		@Optional() @Inject(PAYPAL) paypal:Promise<PayPalNamespace>,
		@Optional() @Inject(GOOGLE_MAPS) googleMaps:Promise<Loader>,
		@Optional() @Inject(GOOGLE_PLACES) googlePlaces:Promise<Loader>,
		@Optional() @Inject(MERCADO_PAGO) loadMercadoPago:()=>Promise<unknown>
	){
		effect(()=>{
			this.document.documentElement.classList.remove('app-dark-theme');
			this.document.documentElement.classList.remove('app-light-theme');
			const theme:string|null=this.theme();
			if(theme){
				this.localStorageService.store('theme-id',theme);
				this.document.documentElement.classList.add(theme);
			}
		});
		
		afterNextRender(async():Promise<void>=>{
			paypal.then((paypal:PayPalNamespace):void=>{
				this.paypal=paypal;
			});
			googleMaps.then(async(googleMaps:Loader):Promise<void>=>{
				this.googleMaps= await googleMaps.importLibrary('maps');
			});
			googlePlaces.then(async(googlePlaces:Loader):Promise<void>=>{
				this.googlePlaces= await googlePlaces.importLibrary('places');
			});
			await loadMercadoPago();
			// @ts-expect-error window.MercadoPago
			this.mercadoPago=new window.MercadoPago(environment.mercadopago.publicKey);
			console.log('mercadoPago',this.mercadoPago);
			hasCookieAccess= await checkHasCookieAccess();
			console.log('hasCookieAccess',hasCookieAccess);
		});
		
		
	}
	
	toggleDarkTheme():void{
		switch(this.theme()){
			case 'app-light-theme':
				this.theme.set('app-dark-theme');
				break;
			case 'app-dark-theme':
				this.theme.set('app-light-theme');
				break;
			case null:
				this.theme.set(this.document.defaultView?.matchMedia('(prefers-color-scheme: dark)').matches ? 'app-light-theme' : 'app-dark-theme');
				break;
		}
	}
	
	getCart(variables:GetCartQueryVariables):Observable<NonNullable<GetCartQuery['getCart']>>{
		return this.getCartGQL
		.fetch(variables,{})
		.pipe(
			map(
				(result:ApolloQueryResult<GetCartQuery>):NonNullable<GetCartQuery['getCart']>=>{
					if(!result.data?.getCart) throw new Error('data not found');
					return result.data.getCart;
				}
			),
			tap(
				(result:NonNullable<GetCartQuery['getCart']>):void=>{
					this.localStorageService.store('cart-id',result.id);
					this.cart.set(result);
				}
			)
		);
	}
	
	createCartItem(variables:CreateCartItemMutationVariables):Observable<CreateCartItemMutation['createCartItem']>{
		return this.createCartItemGQL
		.mutate(variables,{})
		.pipe(
			map((result:MutationResult<CreateCartItemMutation>):CreateCartItemMutation['createCartItem']=>{
				if(!result.data?.createCartItem) throw new Error('data not found');
				return result.data.createCartItem;
			}),
			tap(():void=>{
				this.matSnackBar.openFromComponent(CartItemCreatedSnackComponent);
			}),
			catchError((error)=>{
				this.matSnackBar.openFromComponent(SnackMessageComponent,{
					data:{
						serverErrorInput:error
					}
				});
				throw error;
			})
		);
	}
	
	deleteCartItem(variables:DeleteCartItemMutationVariables):Observable<DeleteCartItemMutation['deleteCartItem']>{
		return this.deleteCartItemGQL
		.mutate(variables,{})
		.pipe(
			map((result:MutationResult<DeleteCartItemMutation>):DeleteCartItemMutation['deleteCartItem']=>{
				if(!result.data?.deleteCartItem) throw new Error('data not found');
				return result.data.deleteCartItem;
			}),
			tap(():void=>{
				this.matSnackBar.openFromComponent(CartItemDeletedSnackComponent);
			}),
			catchError((error)=>{
				this.matSnackBar.openFromComponent(SnackMessageComponent,{
					data:{
						serverErrorInput:error
					}
				});
				throw error;
			})
		);
	}
	
}
