import * as Types from './types.generated';

import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type DeleteCartItemMutationVariables = Types.Exact<{
  id: Types.Scalars['String']['input'];
  product: Types.Scalars['String']['input'];
}>;


export type DeleteCartItemMutation = { __typename?: 'Mutation', deleteCartItem: { __typename?: 'CartItem', createdAt: any, id: string, quantity: number, updatedAt: any, product: { __typename?: 'Product', id: string, name: string } } };

export const DeleteCartItemDocument = gql`
    mutation deleteCartItem($id: String!, $product: String!) {
  deleteCartItem(id: $id, product: $product) {
    createdAt
    id
    quantity
    product {
      id
      name
    }
    updatedAt
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class DeleteCartItemGQL extends Apollo.Mutation<DeleteCartItemMutation, DeleteCartItemMutationVariables> {
    document = DeleteCartItemDocument;
    
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }