import {afterNextRender,Component,Inject,inject,OnInit,PLATFORM_ID,REQUEST} from '@angular/core';
import {SharedModule} from './shared/shared.module';
import {HeaderComponent} from './misc/components/header/header.component';
import {DrawerComponent} from './misc/components/drawer/drawer.component';
import {AppService} from './app.service';
import {DOCUMENT,isPlatformServer} from '@angular/common';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer,Meta,Title} from '@angular/platform-browser';
import {FooterComponent} from './misc/components/footer/footer.component';

@Component({
	selector:'app-root',
	imports:[
		SharedModule,
		HeaderComponent,
		DrawerComponent,
		FooterComponent
	],
	templateUrl:'./app.component.html',
	styleUrl:'./app.component.scss'
})
export class AppComponent implements OnInit{
	appService:AppService=inject(AppService);
	matIconRegistry:MatIconRegistry=inject(MatIconRegistry);
	domSanitizer:DomSanitizer=inject(DomSanitizer);
	
	constructor(
		title:Title,
		meta:Meta,
		@Inject(DOCUMENT) document:Document,
		@Inject(PLATFORM_ID) platformId:object,
		@Inject(REQUEST) request?:Request
	){
		title.setTitle('Bendita');
		meta.updateTag({content:'Bendita'},'property="og:title"');
		meta.updateTag(
			{
				content:(isPlatformServer(platformId) && request) ? request.url : document.location.href
			},
			'property="og:url"'
		);
		meta.updateTag({content:'Plantas & Semillas Abierto las 24 horas'},'name="description"');
		meta.updateTag({content:'Plantas & Semillas Abierto las 24 horas'},'property="og:description"');
		meta.updateTag({content:'bendita, grow, shop, semillas, plantas'},'name="keywords"');
		meta.updateTag({content:'https://www.benditagrow.com/icons/icon-512x512.png'},'property="og:image"');
		
		afterNextRender(():void=>{
			document.defaultView?.scrollTo(0,0);
		});
	}
	
	ngOnInit():void{
		this.matIconRegistry.addSvgIcon('app-facebook',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-facebook.svg'));
		this.matIconRegistry.addSvgIcon('app-twitter',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-twitter.svg'));
		this.matIconRegistry.addSvgIcon('app-instagram',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-instagram.svg'));
		this.matIconRegistry.addSvgIcon('app-whatsapp',this.domSanitizer.bypassSecurityTrustResourceUrl('/images/icon-whatsapp.svg'));
		
	}
	
}
