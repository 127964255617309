import {afterNextRender,Component,DestroyRef,ElementRef,inject,input,InputSignal,Signal,viewChild} from '@angular/core';
import {MatDrawer} from '@angular/material/sidenav';
import {AppService} from '../../../app.service';
import {SharedModule} from '../../../shared/shared.module';
import {AuthenticationService} from '../../../authentication/authentication.service';
import {MatDialog,MatDialogRef} from '@angular/material/dialog';
import {Router} from '@angular/router';
import {LoginPopupComponent} from '../../../authentication/login-popup/login-popup.component';
import {SignupPopupComponent} from '../../../authentication/signup-popup/signup-popup.component';
import {ForgotPasswordPopupComponent} from '../../../authentication/forgot-password-popup/forgot-password-popup.component';
import {debounceTime,distinctUntilChanged,Observable,of,skip,switchMap} from 'rxjs';
import {BreakpointObserver,Breakpoints,BreakpointState} from '@angular/cdk/layout';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {InputEmptyToNullDirective} from '../../directives/input-empty-to-null/input-empty-to-null.directive';
import {FormControl} from '@angular/forms';
import {SearchProductsQuery} from '../../../search/graphql/search-products.generated';
import {CUSTOM_BREAKPOINT} from '../../../common/enums/custom-breakpoint.enum';
import {SearchService} from '../../../search/search.service';
import {MatAutocompleteTrigger} from '@angular/material/autocomplete';

@Component({
	selector:'app-header',
	imports:[
		SharedModule,
		InputEmptyToNullDirective
	],
	templateUrl:'./header.component.html',
	styleUrl:'./header.component.scss'
})
export class HeaderComponent{
	drawer:InputSignal<MatDrawer>=input.required();
	private matDialog:MatDialog=inject(MatDialog);
	private router:Router=inject(Router);
	destroyRef:DestroyRef=inject(DestroyRef);
	breakpointObserver:BreakpointObserver=inject(BreakpointObserver);
	appService:AppService=inject(AppService);
	searchService:SearchService=inject(SearchService);
	authenticationService:AuthenticationService=inject(AuthenticationService);
	searchContainer:Signal<ElementRef|undefined>=viewChild<ElementRef>('searchContainer');
	searchInputHeader:Signal<ElementRef|undefined>=viewChild<ElementRef>('searchInputHeader');
	searchAutocompleteHeaderTrigger:Signal<MatAutocompleteTrigger|undefined>=viewChild<MatAutocompleteTrigger>('searchAutocompleteHeaderTrigger');
	isXSmallWindow:Observable<BreakpointState>=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	isXXSmallWindow:Observable<BreakpointState>=this.breakpointObserver.observe([CUSTOM_BREAKPOINT.XXSmall])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	search:FormControl<string|null>=new FormControl<string|null>(null);
	filteredProducts:Observable<SearchProductsQuery['searchProducts']>=this.search.valueChanges.pipe(
		debounceTime(400),
		distinctUntilChanged(),
		switchMap((value:string|null)=>{
			return value ? this.searchService.searchProducts({limit:3,skip:0,query:value}) : of([]);
		})
	);
	
	constructor(){
		afterNextRender(():void=>{
			this.router.events.pipe(skip(1)).subscribe(():void=>{
				this.searchAutocompleteHeaderTrigger()?.closePanel();
				const searchContainer:ElementRef|undefined=this.searchContainer();
				if(searchContainer) searchContainer.nativeElement.hidden=true;
			});
			
		});
		
	}
	
	openLogin():void{
		const dialogRef:MatDialogRef<LoginPopupComponent>=this.matDialog.open(LoginPopupComponent);
		dialogRef.afterClosed().subscribe((data:any):void=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	openSignup():void{
		const dialogRef:MatDialogRef<SignupPopupComponent>=this.matDialog.open(SignupPopupComponent);
		dialogRef.afterClosed().subscribe((data:any):void=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	openForgotPassword():void{
		const dialogRef:MatDialogRef<ForgotPasswordPopupComponent>=this.matDialog.open(ForgotPasswordPopupComponent);
		dialogRef.afterClosed().subscribe((data:any):void=>{
			if(data?.redirectUrl) this.router.navigate([data.redirectUrl]);
		});
	}
	
	toggleSearch(){
		const searchContainer:ElementRef|undefined=this.searchContainer();
		if(searchContainer){
			if(searchContainer.nativeElement.hidden){
				searchContainer.nativeElement.hidden=false;
				setTimeout(()=>{
					this.searchInputHeader()?.nativeElement.focus();
				},200);
			}else{
				searchContainer.nativeElement.hidden=true;
			}
		}
	}
	
	focusoutSearch(){
		setTimeout(()=>{
			const searchContainer:ElementRef|undefined=this.searchContainer();
			if(searchContainer) searchContainer.nativeElement.hidden=true;
		},200);
	}
	
	navigateSearch(query?:string){
		console.log(' query',query)
		if(query) this.router.navigate(['/search',query]);
	}
	
}
