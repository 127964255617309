import {CanActivateFn,GuardResult} from '@angular/router';
import {CanMatchFn,Router} from '@angular/router';
import {CanActivateChildFn} from '@angular/router';
import {inject} from '@angular/core';
import {filter,firstValueFrom,map} from 'rxjs';
import {UserResponseDto} from './dto/user-response.dto';
import {AuthenticationService} from './authentication.service';
import {toObservable} from '@angular/core/rxjs-interop';

export const authenticationGuardActivate:CanActivateFn=async():Promise<GuardResult>=>{
	const router:Router=inject(Router);
	return firstValueFrom(toObservable(inject(AuthenticationService).user).pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):GuardResult=>{
			if(user){
				return router.parseUrl('/profile');
			}else{
				return true;
			}
		})
	));
};

export const authenticationGuardMatch:CanMatchFn=async():Promise<GuardResult>=>{
	const router:Router=inject(Router);
	return firstValueFrom(toObservable(inject(AuthenticationService).user).pipe(
		filter((user:UserResponseDto|null|undefined):boolean=>user!==undefined),
		map((user:UserResponseDto|null|undefined):GuardResult=>{
			if(user){
				return router.parseUrl('/profile');
			}else{
				return true;
			}
		})
	));
};

export const authenticationGuardActivateChild:CanActivateChildFn=async():Promise<GuardResult>=>{
	return true;
};
