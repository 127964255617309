import {Component,DestroyRef,inject} from '@angular/core';
import {SharedModule} from '../../../shared/shared.module';
import {environment} from '../../../../environments/environment';
import {BreakpointObserver,Breakpoints,BreakpointState} from '@angular/cdk/layout';
import {Observable} from 'rxjs';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {CUSTOM_BREAKPOINT} from '../../../common/enums/custom-breakpoint.enum';

@Component({
	selector:'app-footer',
	imports:[
		SharedModule
	],
	templateUrl:'./footer.component.html',
	styleUrl:'./footer.component.scss'
})
export class FooterComponent{
	private readonly breakpointObserver:BreakpointObserver=inject(BreakpointObserver);
	private readonly destroyRef:DestroyRef=inject(DestroyRef);
	protected readonly environment=environment;
	isXSmallWindow:Observable<BreakpointState>=this.breakpointObserver.observe([Breakpoints.XSmall,Breakpoints.Small])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	isXXSmallWindow:Observable<BreakpointState>=this.breakpointObserver.observe([CUSTOM_BREAKPOINT.XXSmall])
	.pipe(
		takeUntilDestroyed(this.destroyRef)
	);
	
	
}
